import ct from 'countries-and-timezones';
import { camelCase, filter, startCase } from 'lodash';
import moment from 'moment';
import countryCodeData from '../assets/countryCode.json';
import { REGEX, defaultDateFormat } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document.getElementById(portalId);

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);

// Check for document Id's exists end

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (datetime && moment && format) {
    return moment(datetime).format(format);
  }

  return datetime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!',
  },
  characterWithoutWhiteSpace: {
    pattern: REGEX.CHARACTER_WITHOUT_WHITESPACE_IN_STARTING_AND_ENDING,
    message: 'Enter proper value!',
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Should be a valid Number');
      }
      return Promise.resolve();
    },
  }),
  password: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!REGEX.PASSWORD.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject();
      }
      return Promise.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice).toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formItemProps = { normalize: (value) => value.trim() };
export const titleCase = (str = '') => startCase(camelCase(str));

export const checkPasswordCharacters = (value) => {
  const data = [];
  if (value?.length >= 8 && value?.length <= 16) {
    data.push('8_letter');
  }
  if (/[A-Z]/.test(value)) {
    data.push('caps');
  }
  if (/[0-9]/.test(value)) {
    data.push('number');
  }
  if (/[a-z]/.test(value)) {
    data.push('small');
  }
  if (/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
    data.push('special');
  }
  return data;
};

export function getPhoneCodeFromTimezone(timezone) {
  const tz = ct?.getTimezone(timezone);
  const phoneCode = filter(
    countryCodeData,
    (data) =>
      data?.countryCode?.toLocaleLowerCase() ===
      tz?.countries[0]?.toLocaleLowerCase(),
  )?.[0]?.dialCode;
  return phoneCode;
}

export const removeHistoryStateData = (
  navigate,
  location,
  field,
  subField = '',
) => {
  const data = { ...location?.state };
  if (subField) {
    delete data[field]?.[subField];
  } else {
    delete data[field];
  }
  navigate(location?.pathname, data);
};
