import { useQuery } from '@apollo/client';
import { Button, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  ROUTES,
  SCROLL_CONST,
  defaultDateFormat,
} from '../../../common/constants';
import { removeHistoryStateData } from '../../../common/utils';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import useRouter from '../../../hooks/useRouter';
import { GET_ORGANIZATIONS } from '../graphql/Queries';

const limit = DEFAULT_PAGE_SIZE;
const initialUserFilter = {
  skip: 0,
  limit: 10,
  search: '',
  sortBy: {
    field: 'updatedAt',
    order: 'DESC',
  },
};
const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: limit,
  defaultPageSize: 10,
};
const OrganizationTable = () => {
  const { location, navigate } = useRouter();
  const navPagination = location?.state?.navPagination;
  const navFilter = location?.state?.navFilter;
  const [newData, setNewData] = useState([]);
  const [userFilter, setUserFilter] = useState(navFilter || initialUserFilter);
  const [sortedInfo, setSortedInfo] = useState({});
  const [totalData, setTotalData] = useState(0);

  const [paginationProp, setPaginationProp] = useState(
    navPagination || initialPaginationValue,
  );
  const { tenantId } = useParams();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  useEffect(() => {
    setUserFilter(navFilter || initialUserFilter);
  }, [isDesktopViewport, navFilter]);

  const { loading, fetchMore } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      filter: {
        tenantId: Number(tenantId),
        ...userFilter,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const tenantsData = res?.tenantList?.data;
      setNewData(tenantsData);
      setTotalData(res?.tenantList?.total);
    },
    onError() {},
  });

  const ChangeStatus = ({ record }) => {
    if (record?.isDisabled === false) {
      return <Tag className="active-tag">Active</Tag>;
    }
    return <Tag className="inactive-tag">Inactive</Tag>;
  };

  const handleScroll = async (e) => {
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    const hasMore = totalData !== newData?.length;
    if (scrolledToBottom && hasMore && !loading) {
      const { data } = await fetchMore({
        variables: {
          filter: {
            ...userFilter,
            skip: newData?.length,
            limit,
          },
        },
      });
      if (data?.tenantList?.data) {
        const datalist = [...newData, ...data?.tenantList?.data];
        setNewData(datalist);
      }
    }
  };

  const handleTableChange = (pagination, paginationFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination?.pageSize,
        sortBy: {
          field: sorter?.columnKey,
          order: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
    } else {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination?.pageSize,
        sortBy: { field: 'updatedAt', order: 'DESC' },
      });
    }
  };

  const columns = [
    {
      title: 'ID',
      key: 'id',
      render: (text, record, index) => {
        return (
          <>
            {(paginationProp?.current - 1) * paginationProp?.pageSize +
              index +
              1}
          </>
        );
      },
    },
    {
      title: 'ORGANIZATION NAME',
      dataIndex: 'organizationName',
      width: '35%',
      key: 'organizationName',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'organizationName' && sortedInfo?.order,
    },
    {
      title: 'USERS',
      dataIndex: '',
      key: 'users',
      render: (text, record) => {
        return `${record?.userCount}/${record?.userLimit}`;
      },
    },
    {
      title: 'ACTIVATE DATE',
      dataIndex: '',
      key: 'activateDate',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'activateDate' && sortedInfo?.order,
      render: (validity, record) => {
        return moment(`${record?.subscription?.updatedAt}`).format(
          defaultDateFormat,
        );
      },
    },
    {
      title: 'VALID TILL',
      dataIndex: '',
      key: 'validTill',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'validTill' && sortedInfo?.order,
      render: (validity, record) => {
        return moment(`${record?.subscription?.validTill}`).format(
          defaultDateFormat,
        );
      },
    },
    {
      title: 'PENDING DAYS',
      dataIndex: '',
      key: 'pendingDays',
      render: (validity, record) => {
        return moment(record?.subscription?.validTill).diff(moment(), 'days') >
          0
          ? moment(record?.subscription?.validTill).diff(moment(), 'days')
          : 0;
      },
    },
    {
      title: 'STATUS',
      dataIndex: '',
      key: 'status',
      render: (status, record) => {
        return <ChangeStatus record={record} />;
      },
    },
  ];

  return (
    <div>
      <Portal portalId="add-search-organization">
        <div className="organization-search">
          <SearchComponent
            id="search-container-id"
            name="Organizations"
            getData={(value) => {
              setUserFilter({ ...userFilter, search: value, skip: 0 });
              setPaginationProp(initialPaginationValue);
              if (!value) {
                removeHistoryStateData(
                  navigate,
                  location,
                  'userFilter',
                  'search',
                );
              }
            }}
            defaultValue={navFilter?.search}
          />
        </div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          type="primary"
          shape="round"
          onClick={() =>
            navigate(`${ROUTES.ORGANIZATION}/add`, {
              state: {
                navPagination: paginationProp,
                navFilter: userFilter,
                currentPath: location?.pathname,
              },
            })
          }
        >
          {isDesktopViewport ? 'Add Organization' : 'Add'}
        </Button>
      </Portal>
      {isDesktopViewport ? (
        <div className=" pointer">
          <CommonTable
            loadingData={
              loading ? (
                <div className="launch-loader">
                  <LoaderComponent />
                </div>
              ) : (
                ''
              )
            }
            columns={columns}
            data={newData}
            onChange={handleTableChange}
            paginationConfig={{ ...paginationProp, total: totalData }}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`${ROUTES.ORGANIZATION}/${record?.id}/details`, {
                    state: {
                      navPagination: paginationProp,
                      navFilter: userFilter,
                      currentPath: location?.pathname,
                    },
                  });
                },
              };
            }}
            rowKey={(obj) => obj.id}
          />
        </div>
      ) : (
        <div className="organization-card pointer" onScroll={handleScroll}>
          {newData?.map((data) => {
            return (
              <div
                key={data?.id}
                onClick={() => {
                  navigate(`${ROUTES.ORGANIZATION}/${data?.id}/details`, {
                    state: {
                      navPagination: paginationProp,
                      navFilter: userFilter,
                      currentPath: location?.pathname,
                    },
                  });
                }}
              >
                <CommonCard>
                  <div className="common-card">
                    <div className="card-header">
                      <h1 className="break-all-words">
                        {data?.organizationName}
                      </h1>
                      <span>
                        <ChangeStatus record={data} />
                      </span>
                    </div>
                    <div className="card-content">
                      <br />
                      <p>Users: {`${data?.userCount}/ ${data?.userLimit}`}</p>
                      <p>
                        <span>
                          Activate Date:
                          {moment(`${data?.subscription?.updatedAt}`).format(
                            defaultDateFormat,
                          )}
                        </span>
                      </p>
                      <p>
                        <span>
                          Valid Till:
                          {moment(`${data?.subscription?.validTill}`).format(
                            defaultDateFormat,
                          )}
                        </span>
                      </p>
                      <p>
                        <span>
                          Pending Days:
                          {moment(data?.subscription?.validTill).diff(
                            moment(),
                            'days',
                          ) > 0
                            ? moment(data?.subscription?.validTill).diff(
                                moment(),
                                'days',
                              )
                            : 0}
                        </span>
                      </p>
                    </div>
                  </div>
                </CommonCard>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OrganizationTable;
