import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { includes, keys, nth, values } from 'lodash';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import countryCodeData from '../../assets/countryCode.json';
import {
  MOBILE_NUMBER_NOTE,
  REGEX,
  ROLES,
  ROLES_KEYS,
} from '../../common/constants';
import {
  formValidatorRules,
  getPhoneCodeFromTimezone,
  titleCase,
} from '../../common/utils';
import PhoneNumberInputWithCountyCode from '../../components/PhoneNumberInputWithCountyCode';
import {
  CREATE_ORGANIZATION_USERS,
  UPDATE_ORGANIZATION_USERS,
} from './graphql/Mutations';

const AddEditUserModal = ({
  showModal = false,
  setShowModal,
  userData,
  fetchUsers,
  organizationData,
}) => {
  const fetchData = nth(organizationData?.tenantList?.data, 0);
  const [form] = useForm();
  const { required, characterWithoutWhiteSpace, email } = formValidatorRules;
  const { id } = useParams();
  const [createUser, { loading: createLoading }] = useMutation(
    CREATE_ORGANIZATION_USERS,
    {
      onError() { },
      onCompleted: () => {
        setShowModal(false);
        fetchUsers();
      },
    },
  );

  const [updateUsers, { loading: updateLoading }] = useMutation(
    UPDATE_ORGANIZATION_USERS,
    {
      onError() { },
      onCompleted: () => {
        setShowModal(false);
        fetchUsers();
      },
    },
  );

  useMemo(() => {
    if (fetchData?.timeZone) {
      const phoneCode = getPhoneCodeFromTimezone(fetchData?.timeZone);
      const currentPhoneCode = countryCodeData?.find(({ dialCode }) => dialCode === phoneCode)?.id
      form.setFieldsValue({
        countryCode: currentPhoneCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData?.timeZone]);

  const onFinish = (e) => {
    // eslint-disable-next-line no-shadow
    const { name, roles, phoneNo, email, countryCode } = e;
    const code = countryCodeData?.find(({ id: idx }) => idx === countryCode)
    if (!userData) {
      createUser({
        variables: {
          data: {
            name,
            email,
            phoneNo: `${code?.dialCode}${phoneNo}`,
            country: code?.countryCode?.toUpperCase(),
            countryCode: code?.dialCode,
            tenantId: Number(id),
          },
        },
      });
    } else {
      updateUsers({
        variables: {
          id: userData?.id,
          data: {
            tenantId: Number(id),
            roles,
          },
        },
      });
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleRoles = () => {
    if (userData) {
      return values(ROLES_KEYS).map((roles) => (
        <Select.Option key={roles} value={roles}>
          {titleCase(roles)}
        </Select.Option>
      ));
    }
    return (
      <Select.Option
        key={ROLES_KEYS.SYSTEM_ADMIN}
        value={ROLES_KEYS.SYSTEM_ADMIN}
      >
        {titleCase(ROLES.SYSTEM_ADMIN)}
      </Select.Option>
    );
  };

  return (
    <>
      <Modal open={showModal} centered footer={null} closable={false}>
        <h2>User Details</h2>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={
            userData
            && { name: userData?.name, roles: userData?.roles }
          }
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              required,
              characterWithoutWhiteSpace,
              {
                max: 100,
                message: 'should not be more than 100 characters',
              },
            ]}
          >
            <Input allowClear placeholder="Enter Name" disabled={!!userData} />
          </Form.Item>
          <Form.Item label="Role" name="roles" rules={[required]}>
            <Select name="access" placeholder="Select a Role" allowClear>
              {handleRoles()}
              {!includes(keys(ROLES_KEYS), userData?.roles?.[0]) && (
                <Select.Option
                  key="other-roles"
                  value={userData?.roles?.[0]}
                  disabled
                >
                  {titleCase(userData?.roles?.[0])}
                </Select.Option>
              )}
            </Select>
          </Form.Item>
          {!userData && (
            <>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  required,
                  email,
                  {
                    max: 254,
                    message: 'should not be more than 254 characters',
                  },
                ]}
              >
                <Input allowClear placeholder="Enter Email" />
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name="phoneNo"
                dependencies={['countryCode']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!getFieldValue('countryCode')) {
                        return Promise.reject(
                          new Error('Please Select Country Code'),
                        );
                      }
                      if (!value) {
                        return Promise.reject(new Error('Enter Mobile Number'));
                      }
                      if (value && !value.match(REGEX.PHONE)) {
                        return Promise.reject(
                          new Error('should be a valid contact number'),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <PhoneNumberInputWithCountyCode />
              </Form.Item>
              <div className="text-danger note-text note-margin ">
                Note : {MOBILE_NUMBER_NOTE}
              </div>
            </>
          )}
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              className="save-button"
              htmlType="submit"
              loading={createLoading || updateLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditUserModal;
