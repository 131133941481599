/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  ORGANIZATION: '/organization',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  AUTHENTICATION: '/authentication',
  FORGOT: '/forgot-password',
  CHANGE: '/change-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  MAINTENANCE: '/maintenance',
  GALLERY: '/gallery',
  ADD: '/add',
  EDIT: '/edit/:id',
  DETAILS: '/:id/details',
  USER: '/:id/users',
};

/*  Modules */
export const MODULES = {
  ORGANIZATIONS: 'Organizations',
  USERS: 'Users',
  GALLERY: 'Gallery',
};

export const INS_STATUS_KEYS = {
  NOTICE: 'NOTICE',
  RAISED: 'RAISED',
  RESPONDED: 'RESPONDED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const TIMEOUT_TIME_MS = 3000;
export const POLLING_INTERVAL = 15000;
export const PING_RESOURCE = `/logo192.png`;
export const DROPDOWN_LIMIT = 25;
export const SCROLL_PERCENT = 0.85;
export const NUMBER_OF_SKELETON_CARDS = 3;
export const DEFAULT_PAGE_SIZE = 10;

export const ACCESSTYPE = {
  INSTRUCTION: 'Instruction',
  INSPECTION: 'Inspection',
};

export const ACCESSTYPE_KEYS = {
  INSTRUCTION: 'INSTRUCTION',
  INSPECTION: 'INSPECTION',
  REGISTER: 'REGISTER',
};

export const ORGANIZATION_TYPES = {
  CONTRACTOR: 'Contractor',
  PMC: 'PMC',
  OWNER: 'Owner',
  DEVELOPER: 'Developer',
  DESIGN_AND_BUILD: 'Design & Build',
};

export const ROLES = {
  SYSTEM_ADMIN: 'SYSTEM ADMIN',
  USER: 'USER',
};

export const ADDONS = {
  WHATSAPP: 'WHATSAPP',
  INSTRUCTION_WHATSAPP: 'INSTRUCTION_WHATSAPP',
  MULTI_LEVEL_APPROVAL: 'MULTI_LEVEL_APPROVAL',
  INSPECTION_GALLERY_ACCESS: 'INSPECTION_GALLERY_ACCESS',
  TARGET_ACTUAL_METRIC: 'TARGET_ACTUAL_METRIC',
  NOMENCLATURE: 'NOMENCLATURE',
  RFI: 'RFI',
  REPORT_CONFIG: 'REPORT_CONFIG',
};

export const ROLES_KEYS = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  USER: 'USER',
};
export const ALL_ROLES_KEYS = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  USER: 'USER',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
  CHECKLIST_MAKER: 'CHECKLIST_MAKER',
};

export const IMAGE_FILTERS = {
  ALL_IMAGES: 'All Image',
  INSPECTIONS: 'Inspection',
  INSTRUCTIONS: 'Instruction',
};

export const SCROLL_CONST = 5;

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 750,
  desktop: 1000,
  hd: 1200,
};

/* Date and time */
export const defaultDateFormat = 'DD/MM/YYYY';
export const DEFAULTDATETIMEFORMATFORREPORTS = 'YYYY-MM-DD';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{1,3}[)]?[0-9]{4,12}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  NUMBER_GREATER_THAN_ZERO: /^[1-9]\d*$/,
  CHARACTER_WITHOUT_WHITESPACE_IN_STARTING_AND_ENDING: /^(?!\s).+(?<!\s)$/,
};

export const MOBILE_NUMBER_NOTE =
  'Please do not include "0" as a prefix when adding a mobile number';

export const ACCESS_LIMIT = {
  PHOTO_PER_CHECKLIST: 30,
  STAGES_PER_CHECKLIST: 30,
  APPROVAL_LEVEL_PER_CHECKLIST: 3,
  REGISTER_CREATE_MAX_LIMIT: 999,
};

export const EXCEPT_THIS_SYMBOLS = ['e', 'E', '+', '-', '.'];
