import { Form, Input, Select } from 'antd';
import { map } from 'lodash';
import React from 'react';
import countryCode from '../assets/countryCode.json';
import { EXCEPT_THIS_SYMBOLS } from '../common/constants';

const PhoneNumberInputWithCountyCode = ({
  formItemName = 'countryCode',
  ...rest
}) => {
  return (
    <Input
      onKeyDown={(e) =>
        EXCEPT_THIS_SYMBOLS.includes(e?.key) && e?.preventDefault()
      }
      className="country-code-phone-input"
      addonBefore={
        <Form.Item name={formItemName} className="country-code-input">
          <Select
            showSearch
            placeholder="Code"
            options={[
              ...map(countryCode, (record) => ({
                label: record?.dialCode,
                value: record?.id,
              })),
            ]}
            optionFilterProp="label"
          />
        </Form.Item>
      }
      placeholder="Enter Mobile Number"
      {...rest}
    />
  );
};

export default PhoneNumberInputWithCountyCode;
